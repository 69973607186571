var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.product.id ? 'Thay đổi thông tin SP' : 'Thêm thông tin SP',"show":_vm.showing,"closeOnBackdrop":false,"centered":""},on:{"update:show":function($event){_vm.showing=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":function($event){_vm.showing = false}}},[_vm._v(" Hủy ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.okHandle}},[_vm._v(" Đồng ý ")])]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[(_vm.product.id && _vm.product.ProductId)?_c('CInput',{attrs:{"type":"text","value":_vm.product.ProductName,"disabled":""}}):_c('div',{staticClass:"form-group"},[_c('v-select',{class:{
            'is-invalid border-danger rounded': !_vm.product.ProductId,
          },attrs:{"options":_vm.products && _vm.products.length
              ? _vm.products.map(function (_) {
                  return {
                    value: _.Id.toString(),
                    label: _.Name,
                  };
                })
              : [],"reduce":function (p) { return p.value; },"label":"label","placeholder":"Chọn Sản phẩm"},model:{value:(_vm.product.ProductId),callback:function ($$v) {_vm.$set(_vm.product, "ProductId", $$v)},expression:"product.ProductId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Vui lòng chọn SP ")])],1)],1),_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"col":"6"}},[_c('CInput',{attrs:{"type":"number","label":"Đặt hàng","placeholder":"Đặt hàng","value":_vm.product.QtyOrder,"min":"0","add-input-classes":{
              'is-invalid': !_vm.product.QtyOrder || _vm.product.QtyOrder < 1,
            },"invalid-feedback":"Nhập SL đặt hàng"},on:{"input":function (e) { return (_vm.product.QtyOrder = e ? parseInt(e) : 0); },"focus":function (e) { return e.target.select(); }}})],1),_c('CCol',{attrs:{"col":"6"}},[_c('CInput',{attrs:{"type":"number","label":"Thực nhận","placeholder":"Thực nhận","value":_vm.product.QtyActual,"min":"0"},on:{"input":function (e) { return (_vm.product.QtyActual = e ? parseInt(e) : 0); },"focus":function (e) { return e.target.select(); }}})],1)],1),_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"12"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú"},model:{value:(_vm.product.Note),callback:function ($$v) {_vm.$set(_vm.product, "Note", $$v)},expression:"product.Note"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }