<template>
  <div>
    <CCard>
      <CCardHeader>
        <span class="font-weight-bold">
          <CIcon name="cilInput" />
          DS Nhập Kho
        </span>
        <div class="d-inline-block float-right align-right">
          <CButton
            color="info"
            size="sm"
            class="mr-2"
            @click.prevent="
              (e) => {
                showFilter = !showFilter;
              }
            "
          >
            <CIcon v-if="showFilter" name="cil-chevron-top" />
            <CIcon v-else name="cil-chevron-bottom" />
          </CButton>

          <CButton
            color="success"
            size="sm"
            class="float-right"
            @click.prevent="
              () => {
                showing = true;
              }
            "
          >
            <CIcon name="cil-plus" />
            Nhập Kho
          </CButton>
        </div>
      </CCardHeader>
      <CCardBody>
        <filter-box v-if="showFilter" @done="(e) => doFilter(e)" />
        <odata-table
          ref="lstInventoryImport"
          url="odata/InventoryImport"
          tableClass="table-striped"
          :filter="filter"
          :expand="
            `Warehouse($select=Name),` +
              `InventoryImportItems($top=1;$count=true;$select=ProductName,QtyActual;$filter=Status eq ${this.$const.STATUS.Active})`
          "
          sortBy="CreatedAt desc"
          :pageSize="pageSize"
          :colSetting="{
            Action: {
              display: '#',
              sortable: false,
              style: 'min-width: 50px;',
            },
            Warehouse: {
              field: 'Warehouse/Name',
              display: 'Kho',
              sortable: false,
              style: 'min-width: 100px',
            },

            ImportDate: {
              field: 'ImportDate',
              display: 'Ngày nhập',
              sortable: true,
              style: 'min-width: 120px',
            },
            WarehouseKeeper: {
              field: 'WarehouseKeeper',
              display: 'Thủ kho',
              sortable: true,
              style: 'min-width: 120px',
            },
            Status: {
              field: 'Status',
              display: 'Trạng thái',
              sortable: true,
              style: 'min-width: 100px',
            },
            ImportDetail: {
              field: 'ImportDetail',
              display: 'Chi tiết',
              sortable: false,
              style: 'min-width: 200px',
            },
          }"
        >
          <template v-slot:tbody="{ rows }">
            <tr v-if="$refs.lstInventoryImport.loading">
              <td colspan="100%">
                <div class="spinner-border m-5" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else v-for="(row, i) in rows" :key="`tr-${i}`">
              <td>
                <div class="text-nowrap">
                  {{ i + 1 + ($refs.lstInventoryImport.page - 1) * pageSize }}.
                </div>
              </td>
              <td>
                <span v-if="row.Warehouse">
                  {{ row.Warehouse ? row.Warehouse.Name : "" }}
                </span>
              </td>

              <td>
                <a
                  href="javascript:"
                  class="text-primary"
                  :title="`Chi tiết Nhập Kho`"
                  @click="
                    (e) => {
                      $router
                        .push({
                          name: 'InventoryImport',
                          query: { id: row.Id },
                        })
                        .catch(() => {});
                    }
                  "
                >
                  {{ $moment(row.ImportDate).format("DD/MM/YYYY HH:mm") }}
                </a>
              </td>
              <td>
                <span>
                  {{ row.WarehouseKeeper }}
                </span>
              </td>
              <td>
                <CBadge :color="getBadge(row.Status)">
                  {{ $const.IMPORT_STATUS_TEXT[row.Status] }}
                </CBadge>
              </td>
              <td class="text-truncate" style="max-width:200px;">
                <span
                  :title="
                    renderDetails(
                      row.InventoryImportItems,
                      row['InventoryImportItems@odata.count']
                    )
                  "
                >
                  {{
                    renderDetails(
                      row.InventoryImportItems,
                      row["InventoryImportItems@odata.count"]
                    )
                  }}
                </span>
              </td>
            </tr>
          </template>
        </odata-table>
      </CCardBody>
    </CCard>

    <detail
      :showing.sync="showing"
      :importId="routeImportId"
      @reload="$refs.lstInventoryImport.loadData()"
    />
  </div>
</template>

<script>
import odataTable from "@/components/odataTable.vue";
import detail from "./Detail.vue";
import filterBox from "./Filter.vue";

export default {
  name: "InventoryImport",
  components: {
    odataTable,
    detail,
    filterBox,
  },
  data() {
    return {
      pageSize: 20,
      showing: false,
      showFilter: false,
      filters: null,
    };
  },
  mounted() {
    //let importId = this.$route.query.id;
    if (this.routeImportId) {
      this.showing = true;
    }
  },
  computed: {
    filter() {
      let filters = [];

      if (this.filters && this.filters.length) {
        filters = [...this.filters];
      }

      return filters.join(" and ");
    },

    routeImportId() {
      let importId = this.$route.query.id;
      return !isNaN(importId) ? importId : 0;
    },
  },
  watch: {
    routeImportId(val) {
      this.showing = !!val;
    },
  },

  methods: {
    doFilter(e) {
      if (JSON.stringify(this.filters) != JSON.stringify(e)) {
        this.filters = e;
      } else {
        this.$refs.lstInventoryImport.loadData();
      }
    },

    renderDetails(importItems, count) {
      if (importItems && importItems.length) {
        let list = [];
        list.push(
          `Nhập ${importItems[0].QtyActual} ${importItems[0].ProductName}`
        );
        count > 1 && list.push(`${count - 1} sản phẩm khác`);
        return list.join(" và ");
      }
      return null;
    },

    getBadge(status) {
      switch (status) {
        case this.$const.IMPORT_STATUS.Approved:
          return "success";
        case this.$const.IMPORT_STATUS.InComing:
          return "info";
        case this.$const.IMPORT_STATUS.Waiting:
          return "warning";
        case this.$const.IMPORT_STATUS.Cancelled:
          return "secondary";
        case this.$const.IMPORT_STATUS.Rejected:
          return "danger";
        default:
          "primary";
      }
    },
  },
};
</script>
