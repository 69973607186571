<template>
  <CModal
    :title="product.id ? 'Thay đổi thông tin SP' : 'Thêm thông tin SP'"
    :show.sync="showing"
    :closeOnBackdrop="false"
    centered
  >
    <CRow>
      <CCol md="12">
        <CInput
          v-if="product.id && product.ProductId"
          type="text"
          :value="product.ProductName"
          disabled
        />
        <div v-else class="form-group">
          <v-select
            v-model="product.ProductId"
            :options="
              products && products.length
                ? products.map((_) => {
                    return {
                      value: _.Id.toString(),
                      label: _.Name,
                    };
                  })
                : []
            "
            :reduce="(p) => p.value"
            label="label"
            placeholder="Chọn Sản phẩm"
            :class="{
              'is-invalid border-danger rounded': !product.ProductId,
            }"
          />
          <div class="invalid-feedback">
            Vui lòng chọn SP
          </div>
        </div>
      </CCol>
      <CCol md="12">
        <div class="form-row">
          <CCol col="6">
            <CInput
              type="number"
              label="Đặt hàng"
              placeholder="Đặt hàng"
              :value="product.QtyOrder"
              min="0"
              :add-input-classes="{
                'is-invalid': !product.QtyOrder || product.QtyOrder < 1,
              }"
              invalid-feedback="Nhập SL đặt hàng"
              @input="(e) => (product.QtyOrder = e ? parseInt(e) : 0)"
              @focus="(e) => e.target.select()"
            />
          </CCol>
          <CCol col="6">
            <CInput
              type="number"
              label="Thực nhận"
              placeholder="Thực nhận"
              :value="product.QtyActual"
              min="0"
              @input="(e) => (product.QtyActual = e ? parseInt(e) : 0)"
              @focus="(e) => e.target.select()"
            />
            <!--              :add-input-classes="{
                'is-invalid': !product.QtyActual || product.QtyActual < 1,
              }"
              invalid-feedback="Nhập SL thực nhận"-->
          </CCol>
        </div>

        <div class="form-row">
          <CCol md="12">
            <CInput
              v-model="product.Note"
              label="Ghi chú"
              placeholder="Ghi chú"
            />
          </CCol>
        </div>
      </CCol>
    </CRow>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="showing = false">
        Hủy
      </button>
      <button type="button" class="btn btn-primary" @click="okHandle">
        Đồng ý
      </button>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ["updating", "editingDetail"],
  data() {
    return {
      products: null,
      showing: false,
      product: {
        ProductId: 0,
      },
    };
  },
  watch: {
    editingDetail: {
      deep: true,
      handler(val) {
        this.product = val;
      },
    },

    "product.ProductId"(val) {
      if (val) {
        let product = this.products.find((_) => _.Id == val);
        this.product.ProductName = product ? product.Name : "";
        // this.product.QtyOrder = 0;
        // this.product.QtyActual = 0;
        // this.product.Note = "";
      } else {
        this.product.ProductName = "";
        // this.product.QtyOrder = 0;
        // this.product.QtyActual = 0;
        // this.product.Note = "";
      }
    },

    updating(val) {
      this.showing = val;
    },
    showing(val) {
      this.$emit("update:updating", val);
    },
  },
  async mounted() {
    if (this.products && this.products.length) {
      return;
    }
    this.products = await this.loadProducts();
  },
  methods: {
    async loadProducts() {
      let products = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/Product", {
        params: {
          $top: 1000,
          $filter: `Status eq ${active}`,
          $select: "Id,Name,ProductType",
        },
      });
      if (resp && resp.status == 200) {
        products = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: `${_.Name} - ${
              this.$const.PRODUCT_TYPES_TEXT[_.ProductType]
            }`,
            QtyOrder: 0,
            QtyActual: 0,
            Note: null,
          };
        });
      }
      return products;
    },

    okHandle() {
      if (
        this.product &&
        this.product.ProductId &&
        this.product.QtyOrder //&&
        //this.product.QtyActual
      ) {
        //let product = Object.assign({}, this.product);
        this.product.ProductId = parseInt(this.product.ProductId);
        this.$emit("choose", this.product);
        this.showing = false;
      }
    },
  },
};
</script>
