<template>
  <div class="d-inline-block" v-if="inventoryImport && inventoryImport.Id">
    <CButton
      class="btn btn-warning"
      :disabled="loading"
      @click="generateReport"
    >
      <CIcon name="cil-print" custom-classes="c-icon m-0" />
      In Phiếu Nhập Kho
    </CButton>
    <div class="d-inline-block font-italic" v-if="loading">
      <CSpinner color="info" size="sm" class="ml-2" />
      Đang xử lý...
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="987"
      :filename="
        `Import_${$moment(inventoryImport.ImportDate).format('YYYYMMDDHHmm')}`
      "
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a5"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @hasStartedGeneration="loading = true"
      @hasGenerated="loading = false"
      ref="html2Pdf"
    >
      <section slot="pdf-content" class="receipt-document small">
        <div class="header">
          <div class="row align-items-center">
            <div class="col-3">
              <img src="/img/logo.png" class="img-fluid" />
            </div>
            <div class="col-9">
              <div
                class="text-uppercase font-weight-bold text-center"
                style="font-size:1rem;"
              >
                CÔNG TY CỔ PHẦN TẬP ĐOÀN K-GROUP
              </div>
              <div class="text-center">
                Địa chỉ: G1A, Đường Đồng Khởi, Khu phố 4, Phường Tân Hiệp, Thành
                phố Biên Hoà, Tỉnh Đồng Nai, Việt Nam
              </div>
              <div class="row">
                <div class="col-3">
                  Kho:
                  <span class="font-weight-bold">
                    {{ inventoryImport.Warehouse.Name }}
                  </span>
                </div>
                <div class="col-3">
                  <div class="overflow-hidden text-nowrap">
                    Từ Kho:
                    <span
                      class="font-weight-bold"
                      v-if="inventoryImport.FromWarehouseId"
                    >
                      {{ inventoryImport.FromWarehouse.Name }}
                    </span>
                    <span v-else>{{ ".".repeat(200) }}</span>
                  </div>
                </div>
                <div class="col-6 text-right">
                  Thời gian nhập kho:
                  <span class="font-weight-bold">
                    {{
                      $moment(inventoryImport.ImportDate).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body mt-1">
          <div class="bill-title text-center">
            <div class="text-danger font-weight-bold" style="font-size:1.5rem;">
              PHIẾU NHẬP KHO
            </div>
          </div>

          <hr
            class="my-1"
            style="border-top: 1px solid;border-color: rgba(0, 0, 10, 0.05);"
          />

          <div class="row">
            <div class="col-4">
              <div class="overflow-hidden text-nowrap">
                Đơn vị giao hàng:
                <span
                  v-if="inventoryImport.ShippingCpny"
                  class="font-weight-bold"
                >
                  {{ inventoryImport.ShippingCpny }}
                </span>
                <span v-else>
                  {{ ".".repeat(200) }}
                </span>
              </div>
            </div>
            <div class="col-4">
              <div class="overflow-hidden text-nowrap">
                Cước vận chuyển:
                <span
                  v-if="inventoryImport.ShippingFee"
                  class="font-weight-bold"
                >
                  {{ $func.addCommas(inventoryImport.ShippingFee) }} VND
                </span>
                <span v-else>
                  {{ ".".repeat(200) }}
                </span>
              </div>
            </div>
            <div class="col-4">
              <div class="overflow-hidden text-nowrap">
                Cước chành xe:
                <span
                  v-if="inventoryImport.StrappingFee"
                  class="font-weight-bold"
                >
                  {{ $func.addCommas(inventoryImport.StrappingFee) }} VND
                </span>
                <span v-else>
                  {{ ".".repeat(200) }}
                </span>
              </div>
            </div>
          </div>

          <div class="order-detail py-2">
            <table class="table table-bordered table-sm mb-0">
              <thead>
                <tr>
                  <th
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    STT
                  </th>
                  <th
                    class="text-center"
                    style="min-width: 100px;max-width:150px;"
                  >
                    Tên Sản Phẩm
                  </th>
                  <th
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    ĐVT
                  </th>
                  <th
                    class="text-center"
                    style="min-width: 50px;max-width:100px;"
                  >
                    SL Đặt Hàng
                  </th>
                  <th
                    class="text-center"
                    style="min-width: 50px;max-width:100px;"
                  >
                    SL Thực Nhận
                  </th>
                  <th
                    class="text-center"
                    style="min-width: 150px;max-width:200px;"
                  >
                    Ghi chú
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(importItem,
                  i) in inventoryImport.InventoryImportItems"
                  :key="`importItem-${i}`"
                >
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    {{ i + 1 }}
                  </td>
                  <td style="min-width: 100px;max-width:150px;">
                    {{ importItem.ProductName }}
                  </td>
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    Cái
                  </td>
                  <td
                    class="text-right"
                    style="min-width: 50px;max-width:100px;"
                  >
                    {{ importItem.QtyOrder }}
                  </td>
                  <td
                    class="text-right"
                    style="min-width: 50px;max-width:100px;"
                  >
                    {{ importItem.QtyActual }}
                  </td>
                  <td
                    class="text-right"
                    style="min-width: 150px;max-width:200px;"
                  >
                    {{ importItem.Note }}
                  </td>
                </tr>
                <tr v-for="item in remainArr" :key="`item-${item}`">
                  <td
                    class="text-center"
                    style="min-width: 30px;max-width:30px;"
                  >
                    <span style="color:transparent;">{{
                      inventoryImport.InventoryImportItems.length + item + 1
                    }}</span>
                  </td>
                  <td style="min-width: 100px;max-width:150px;"></td>
                  <td
                    class="text-right"
                    style="min-width: 30px;max-width:30px;"
                  ></td>
                  <td
                    class="text-right"
                    style="min-width: 50px;max-width:100px;"
                  ></td>
                  <td
                    class="text-right"
                    style="min-width: 50px;max-width:100px;"
                  ></td>
                  <td
                    class="text-right"
                    style="min-width: 150px;max-width:200px;"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="inventoryImport.Note" class="font-italic">
            * Ghi chú: {{ inventoryImport.Note }}
          </div>
        </div>
        <div class="footer mt-2">
          <div class="form-row">
            <div class="col-3 text-center">
              <span class="font-weight-bold">
                Thủ Kho
              </span>
              <br />
              <span class="font-italic">
                (ký, họ tên)
              </span>
              <br />
              <div class="mt-5" v-if="inventoryImport.WarehouseKeeper">
                {{ inventoryImport.WarehouseKeeper }}
              </div>
            </div>
            <div class="col-3 text-center">
              <span class="font-weight-bold">
                Tài Xế
              </span>
              <br />
              <span class="font-italic">
                (ký, họ tên)
              </span>
              <br />
              <div class="mt-5" v-if="inventoryImport.Driver">
                {{ inventoryImport.Driver }}
              </div>
            </div>
            <div class="col-3 text-center">
              <span class="font-weight-bold">
                Bảo Vệ
              </span>
              <br />
              <span class="font-italic">
                (ký, họ tên)
              </span>
              <br />
              <div class="mt-5" v-if="inventoryImport.Security">
                {{ inventoryImport.Security }}
              </div>
            </div>
            <div class="col-3 text-center">
              <span class="font-weight-bold">
                Người Giao Hàng
              </span>
              <br />
              <span class="font-italic">
                (ký, họ tên)
              </span>
              <br />
              <div class="mt-5" v-if="inventoryImport.Shipper">
                {{ inventoryImport.Shipper }}
              </div>
            </div>
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },
  props: ["inventoryImport"],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    remainArr() {
      let result = [];
      let count = 10 - this.inventoryImport.InventoryImportItems.length;
      for (let i = 0; i < count; i++) {
        result.push(i);
      }
      return result;
    },
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped>
.receipt-document {
  padding: 2rem;

  .order-detail {
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 15%;
      left: calc(50% - 150px);
      background-image: url("/img/logo.png");
      background-size: 300px auto;
      // background-position: 30px 30px;
      background-repeat: no-repeat;
      opacity: 0.1;
      height: calc(100vh);
    }
    table {
      th,
      td {
        padding-top: 0;
        line-height: 0.8rem;
      }
    }
  }
}
</style>
