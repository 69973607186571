<template>
  <div>
    <CModal
      :title="
        inventoryImport && inventoryImport.Id
          ? `Cập nhật Nhập Kho ${$moment(inventoryImport.ImportDate).format(
              'DD/MM/YYYY HH:mm'
            )}`
          : 'Tạo Nhập Kho'
      "
      :show.sync="detailModal"
      :closeOnBackdrop="false"
      centered
      size="xl"
    >
      <div v-if="loading" class="spinner-border m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>

      <template v-if="inventoryImport" v-slot:body-wrapper>
        <div
          class="modal-body overflow-auto"
          :style="`height: calc(100vh - 12.1rem);`"
        >
          <div class="form-row">
            <CCol md="3">
              <CInput
                v-if="inventoryImport.Id"
                :value="inventoryImport.Warehouse.Name"
                label="Kho nhập"
                placeholder="Kho nhập"
                disabled
              />
              <div v-else class="form-group">
                <label>Kho nhập</label>
                <v-select
                  v-model="inventoryImport.WarehouseId"
                  :options="warehouses"
                  :reduce="(p) => p.Id"
                  label="Name"
                  placeholder="Chọn kho nhập"
                  :class="{
                    'is-invalid border-danger rounded': !!inValidObject[
                      'inventoryImport.WarehouseId'
                    ],
                  }"
                  @input="inventoryImport.FromWarehouseId = null"
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryImport.WarehouseId"] }}
                </div>
              </div>
            </CCol>
            <CCol md="3">
              <CInput
                v-if="inventoryImport.Id"
                :value="
                  inventoryImport.FromWarehouse
                    ? inventoryImport.FromWarehouse.Name
                    : ''
                "
                label="Nhập từ kho"
                placeholder="Kho xuất"
                disabled
              />
              <div v-else class="form-group">
                <label>Nhập từ kho</label>
                <v-select
                  v-model="inventoryImport.FromWarehouseId"
                  :options="
                    warehousesAll.filter(
                      (_) => _.Id != inventoryImport.WarehouseId
                    )
                  "
                  :reduce="(p) => p.Id"
                  label="Name"
                  placeholder="Chọn kho xuất"
                  :class="{
                    'is-invalid border-danger rounded': !!inValidObject[
                      'inventoryImport.FromWarehouseId'
                    ],
                  }"
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryImport.FromWarehouseId"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <CInput
                v-if="!allowEdit"
                :value="
                  $moment(inventoryImport.ImportDate).format('DD/MM/YYYY HH:mm')
                "
                label="Thời gian nhập kho"
                placeholder="Thời gian nhập kho"
                disabled
              />
              <div v-else role="group" class="form-group">
                <label>
                  Thời gian nhập kho
                </label>
                <datetime
                  type="datetime"
                  v-model="inventoryImport.ImportDate"
                  format="dd/MM/yyyy HH:mm"
                  :input-class="{
                    'form-control': true,
                    'is-invalid': !!inValidObject['inventoryImport.ImportDate'],
                  }"
                  :class="{
                    'is-invalid': !!inValidObject['inventoryImport.ImportDate'],
                  }"
                  value-zone="local"
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryImport.ImportDate"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryImport.ShippingCpny"
                label="Đơn vị giao hàng"
                placeholder="Đơn vị giao hàng"
                :disabled="!allowEdit ? true : false"
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryImport.Shipper"
                label="Người giao hàng"
                placeholder="Người giao hàng"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryImport.Shipper'],
                }"
                :invalid-feedback="inValidObject['inventoryImport.Shipper']"
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryImport.WarehouseKeeper"
                label="Thủ kho"
                placeholder="Thủ kho"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject[
                    'inventoryImport.WarehouseKeeper'
                  ],
                }"
                :invalid-feedback="
                  inValidObject['inventoryImport.WarehouseKeeper']
                "
              />
            </CCol>
            <CCol md="2">
              <div role="group" class="form-group">
                <label>
                  Cước giao hàng
                </label>
                <money
                  :disabled="!allowEdit"
                  placeholder="Cước giao hàng"
                  :value="inventoryImport.ShippingFee"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': !!inValidObject[
                      'inventoryImport.ShippingFee'
                    ],
                  }"
                  @input="
                    (e) => {
                      inventoryImport.ShippingFee = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }
                  "
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryImport.ShippingFee"] }}
                </div>
              </div>
            </CCol>
            <CCol md="2">
              <div role="group" class="form-group">
                <label>
                  Cước chành xe
                </label>
                <money
                  :disabled="!allowEdit"
                  placeholder="Cước chành xe"
                  :value="inventoryImport.StrappingFee"
                  class="form-control text-right"
                  :class="{
                    'is-invalid': !!inValidObject[
                      'inventoryImport.StrappingFee'
                    ],
                  }"
                  @input="
                    (e) => {
                      inventoryImport.StrappingFee = e
                        ? parseFloat(e) >= 0
                          ? parseFloat(e)
                          : 0
                        : 0;
                    }
                  "
                />
                <div class="invalid-feedback">
                  {{ inValidObject["inventoryImport.StrappingFee"] }}
                </div>
              </div>
            </CCol>

            <CCol md="2">
              <CInput
                v-model="inventoryImport.Driver"
                label="Tài xế"
                placeholder="Tài xế"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryImport.Driver'],
                }"
                :invalid-feedback="inValidObject['inventoryImport.Driver']"
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryImport.Security"
                label="Bảo vệ"
                placeholder="Bảo vệ"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryImport.Security'],
                }"
                :invalid-feedback="inValidObject['inventoryImport.Security']"
              />
            </CCol>
            <CCol md="2">
              <CInput
                v-model="inventoryImport.Note"
                label="Ghi chú"
                placeholder="Ghi chú"
                :disabled="!allowEdit ? true : false"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryImport.Note'],
                }"
                :invalid-feedback="inValidObject['inventoryImport.Note']"
              />
            </CCol>
          </div>

          <CRow>
            <CCol md="12">
              <div class="table-responsive">
                <table class="table" ref="lstOrderDetails">
                  <thead>
                    <tr class="bg-light text-dark">
                      <th>STT</th>
                      <th style="min-width:200px;max-width:250px;">
                        Tên SP
                      </th>
                      <th
                        class="text-right"
                        style="min-width:100px;max-width:150px;"
                      >
                        SL đặt hàng
                      </th>
                      <th
                        class="text-right"
                        style="min-width:100px;max-width:150px;"
                      >
                        SL thực nhận
                      </th>
                      <th style="min-width:100px;max-width:200px;">
                        Ghi chú
                      </th>
                      <th style="min-width:50px;max-width:50px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="importItems && importItems.length">
                      <tr
                        v-for="(importItem, i) in importItems"
                        :key="`importItem-${i}`"
                      >
                        <td>{{ i + 1 }}.</td>
                        <td style="min-width:200px;max-width:250px;">
                          <template v-if="inventoryImport.Id">
                            {{ importItem.ProductName }}
                          </template>
                          <a
                            v-else
                            href="javascript:"
                            class="text-dark text-decoration-none"
                            @click="
                              (e) => {
                                updating = true;
                                editingDetail = Object.assign({}, importItem);
                              }
                            "
                          >
                            {{ importItem.ProductName }}
                            <CIcon name="cil-pencil"></CIcon>
                          </a>
                        </td>
                        <td
                          class="text-right"
                          style="min-width:100px;max-width:150px;"
                        >
                          {{ importItem.QtyOrder }}
                        </td>
                        <td
                          class="text-right"
                          style="min-width:100px;max-width:150px;"
                        >
                          {{ importItem.QtyActual }}
                        </td>
                        <td style="min-width: 100px;max-width:200px;">
                          {{ importItem.Note }}
                        </td>
                        <td style="min-width:50px;max-width:50px;">
                          <a
                            v-if="!inventoryImport.Id"
                            href="javascript:"
                            class="text-decoration-none text-danger"
                            @click="
                              (e) => {
                                importItems = importItems.filter(
                                  (_) => _.id != importItem.id
                                );
                              }
                            "
                          >
                            <CIcon name="cil-trash" />
                          </a>
                        </td>
                      </tr>
                    </template>
                    <tr v-if="!inventoryImport.Id">
                      <td></td>
                      <td class="text-right" colspan="100%">
                        <a
                          href="javascript:"
                          class="text-info text-decoration-none"
                          @click="
                            (e) => {
                              updating = true;
                              editingDetail = {
                                ProductId: 0,
                                QtyOrder: 0,
                                QtyActual: 0,
                                Note: null,
                              };
                            }
                          "
                        >
                          <CIcon name="cil-plus"></CIcon>
                          Thêm Sản Phẩm
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>

          <div v-if="inValidObject['importItems']" class="text-danger">
            {{ inValidObject["importItems"] }}
          </div>

          <CRow>
            <CCol md="3">
              <CSelect
                v-if="
                  inventoryImport &&
                    inventoryImport.Id &&
                    importStatusOptions &&
                    importStatusOptions.length
                "
                label="Trạng thái Nhập Kho:"
                placeholder="Trạng thái"
                :value.sync="inventoryImportStatus"
                :options="[
                  { value: null, label: 'Chọn Trạng Thái Nhập Kho' },
                  ...importStatusOptions,
                ]"
                :add-input-classes="{
                  'is-invalid': !!inValidObject['inventoryImportStatus'],
                }"
                :invalid-feedback="inValidObject['inventoryImportStatus']"
              />
            </CCol>
          </CRow>
        </div>
      </template>

      <template v-slot:footer>
        <div class="form-row w-100">
          <CCol
            class="text-left col-4"
            v-if="
              inventoryImport &&
                inventoryImport.Status != $const.IMPORT_STATUS.Cancelled &&
                inventoryImport.Status != $const.IMPORT_STATUS.Rejected
            "
          >
            <receipt :inventoryImport="inventoryImport" />
          </CCol>
          <CCol class="text-right col-8 ml-auto">
            <template v-if="allowEdit">
              <CButton
                class="btn btn-primary"
                :disabled="saving"
                @click="createInventoryImport"
              >
                <CIcon name="cil-save" custom-classes="c-icon m-0" />
                Cập nhật
              </CButton>
              <div class="d-inline-block font-italic" v-if="saving">
                <CSpinner color="info" size="sm" class="ml-2" />
                Đang xử lý...
              </div>
            </template>
            <CButton
              class="btn btn-secondary ml-2"
              @click="detailModal = false"
            >
              <span>×</span>
              Đóng
            </CButton>
          </CCol>
        </div>
      </template>
    </CModal>
    <choose-product
      :updating.sync="updating"
      :editingDetail="editingDetail"
      @choose="updateImportDetail"
    />
  </div>
</template>

<script>
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import ChooseProduct from "./ChooseProduct.vue";
import Receipt from "./Receipt.vue";

export default {
  props: ["showing", "importId"],
  components: {
    ChooseProduct,
    Datetime,
    Receipt,
  },
  data() {
    return {
      detailModal: false,
      inValidObject: {},
      inventoryImport: null,
      saving: false,
      loading: false,
      warehouses: [],
      warehousesAll: [],
      updating: false,
      editingDetail: null,
      importItems: [],
      inventoryImportStatus: null,
    };
  },

  async mounted() {
    await this.loadImportInfo(this.importId);
    this.warehouses = await this.loadWarehouses(false);
    this.warehousesAll = await this.loadWarehouses(true);
  },

  computed: {
    allowEdit() {
      let allow = false;
      let importObj = this.inventoryImport;
      if (importObj) {
        if (importObj.Id) {
          return [
            this.$const.IMPORT_STATUS.Waiting,
            this.$const.IMPORT_STATUS.InComing,
          ].includes(importObj.Status);
        } else {
          allow = true;
        }
      }
      return allow;
    },

    importStatusOptions() {
      let inventoryImport = this.inventoryImport;
      let result = [];

      if (inventoryImport) {
        if (inventoryImport.Status == this.$const.IMPORT_STATUS.Waiting) {
          // FromWarehouseId
          if (inventoryImport.FromWarehouseId) {
            result = [this.$const.IMPORT_STATUS.Cancelled];
          } else {
            result = [
              this.$const.IMPORT_STATUS.Approved,
              this.$const.IMPORT_STATUS.Cancelled,
            ];
          }
        } else if (
          inventoryImport.Status == this.$const.IMPORT_STATUS.InComing
        ) {
          result = [
            this.$const.IMPORT_STATUS.Approved,
            this.$const.IMPORT_STATUS.Rejected,
          ];
        }
      }

      return result
        ? result.map((_) => {
            return { value: _, label: this.$const.IMPORT_STATUS_TEXT[_] };
          })
        : [];
    },
  },

  watch: {
    async showing(val) {
      this.detailModal = val;
      if (!val) {
        this.$router
          .push({
            name: "InventoryImport",
            query: {},
          })
          .catch(() => {});
      }
      await this.loadImportInfo(this.importId);
    },
    detailModal(val) {
      this.$emit("update:showing", val);
    },
    // async importId(val) {
    //   await this.loadImportInfo(val);
    // },
  },

  methods: {
    async loadImportInfo(importId) {
      let result = this.newImportInfo();
      this.loading = true;
      try {
        if (importId) {
          result = null;

          let filters = [];
          filters.push(`Id eq ${importId}`);
          let resp = await this.$http.get(`odata/InventoryImport`, {
            params: {
              $top: 1,
              $filter: filters.join(" and "),
              $expand:
                `InventoryImportItems($filter=Status eq ${this.$const.STATUS.Active}), ` +
                `Warehouse($select=Name),FromWarehouse($select=Name)`,
            },
          });
          if (resp && resp.status == 200 && resp.data.value.length) {
            result = resp.data.value[0];
          }
        }
      } catch {
        //
      }
      this.loading = false;
      this.inventoryImport = result;
      this.importItems = result.InventoryImportItems;
      this.inventoryImportStatus = null;
      this.inValidObject = {};
    },

    async loadWarehouses(isAll) {
      let result = [];
      let active = this.$const.STATUS.Active;
      let resp = await this.$http.get("odata/InventoryWarehouse", {
        params: {
          $top: 200,
          $filter: `Status eq ${active}`,
          $select: "Id,Name,AgencyId",
          isAll: isAll,
        },
      });
      if (resp && resp.status == 200) {
        result = resp.data.value.map((_) => {
          return {
            Id: _.Id,
            Name: _.Name,
            AgencyId: _.AgencyId,
          };
        });
      }
      return result;
    },

    newImportInfo() {
      return {
        WarehouseId: null,
        FromWarehouseId: null,
        ImportDate: new Date().toISOString(),
        ShippingCpny: null,
        ShippingFee: 0,
        StrappingFee: 0,
        WarehouseKeeper: null,
        Driver: null,
        Security: null,
        Shipper: null,
        Note: null,
        InventoryImportItems: [],
      };
    },

    updateImportDetail(product) {
      if (this.importItems.some((_) => _.ProductId == product.ProductId)) {
        // update item detail
        this.importItems
          .filter((_) => _.ProductId == product.ProductId)
          .forEach((_) => {
            _.QtyOrder = product.QtyOrder;
            _.QtyActual = product.QtyActual;
            _.Note = product.Note;
          });
      } else {
        // insert into import details
        let id = this.$func.create_UUID();
        product.id = id;
        this.importItems.push(product);
      }
    },

    async createInventoryImport() {
      let inventoryImport = this.inventoryImport;
      let importItems = this.importItems;

      this.inValidObject = this.checkValid(inventoryImport, importItems);

      if (Object.keys(this.inValidObject).length) {
        alert("Thông tin nhập chưa đúng, vui lòng kiểm tra lại!");
        return;
      }
      if (!importItems || !importItems.length) {
        alert("Chưa có thông tin sản phẩm");
        return;
      }
      this.saving = true;
      try {
        if (inventoryImport.Id) {
          let patchData = {
            Driver: inventoryImport.Driver,
            //ImportDate: new Date(inventoryImport.ImportDate).toISOString(),
            Note: inventoryImport.Note,
            Security: inventoryImport.Security,
            Shipper: inventoryImport.Shipper,
            ShippingCpny: inventoryImport.ShippingCpny,
            ShippingFee: inventoryImport.ShippingFee,
            StrappingFee: inventoryImport.StrappingFee,
            WarehouseKeeper: inventoryImport.WarehouseKeeper,
          };
          if (this.inventoryImportStatus) {
            patchData["Status"] = this.inventoryImportStatus;
          }
          let resp = await this.$http.patch(
            `odata/InventoryImport/${inventoryImport.Id}`,
            patchData
          );
          if (resp) {
            if (resp.status == 204) {
              alert(`Cập nhật Nhập Kho thành công!`);
              this.detailModal = false;
              this.$emit("reload");
            } else if (resp.status == 200 && resp.data.message) {
              let message = this.$const.MESSAGE[resp.data.message]
                ? this.$const.MESSAGE[resp.data.message]
                : resp.data.message;
              let data = resp.data.data;
              if (data) {
                message += ": " + data;
              }
              alert(message);
            }
          }
        } else {
          inventoryImport.InventoryImportItems = importItems.map((_) => {
            return {
              ProductId: _.ProductId,
              ProductName: _.ProductName,
              QtyOrder: _.QtyOrder,
              QtyActual: _.QtyActual,
              Note: _.Note,
            };
          });

          let resp = await this.$http.post(
            `odata/InventoryImport`,
            inventoryImport
          );
          if (resp && resp.status == 201) {
            alert(`Tạo đơn Nhập Kho thành công!`);
            this.detailModal = false;
            this.$emit("reload");
          }
        }
      } catch (error) {
        alert(error);
      }
      this.saving = false;
    },

    checkValid(inventoryImport, importItems) {
      let inValidObject = {};
      let warehouses = this.warehousesAll;

      if (!inventoryImport.WarehouseId) {
        inValidObject["inventoryImport.WarehouseId"] = "Vui lòng chọn Kho Nhập";
      }
      if (!inventoryImport.FromWarehouseId && inventoryImport.WarehouseId) {
        let warehouse = warehouses.find(
          (_) => _.Id == inventoryImport.WarehouseId
        );
        if (warehouse && warehouse.AgencyId) {
          inValidObject["inventoryImport.FromWarehouseId"] =
            "Vui lòng chọn Kho Xuất";
        } else if (importItems && importItems.some((_) => !_.QtyActual)) {
          inValidObject["importItems"] =
            "Nhập kho tổng bắt buộc nhập SL Thực Nhận";
        }
      }
      if (!inventoryImport.ImportDate) {
        inValidObject["inventoryImport.ImportDate"] =
          "Vui lòng chọn Ngày Nhập Kho";
      }
      // if (!inventoryImport.WarehouseKeeper) {
      //   inValidObject["inventoryImport.WarehouseKeeper"] =
      //     "Vui lòng nhập Thủ Kho";
      // }

      return inValidObject;
    },
  },
};
</script>
