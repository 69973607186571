var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CModal',{attrs:{"title":_vm.inventoryImport && _vm.inventoryImport.Id
        ? ("Cập nhật Nhập Kho " + (_vm.$moment(_vm.inventoryImport.ImportDate).format(
            'DD/MM/YYYY HH:mm'
          )))
        : 'Tạo Nhập Kho',"show":_vm.detailModal,"closeOnBackdrop":false,"centered":"","size":"xl"},on:{"update:show":function($event){_vm.detailModal=$event}},scopedSlots:_vm._u([(_vm.inventoryImport)?{key:"body-wrapper",fn:function(){return [_c('div',{staticClass:"modal-body overflow-auto",style:("height: calc(100vh - 12.1rem);")},[_c('div',{staticClass:"form-row"},[_c('CCol',{attrs:{"md":"3"}},[(_vm.inventoryImport.Id)?_c('CInput',{attrs:{"value":_vm.inventoryImport.Warehouse.Name,"label":"Kho nhập","placeholder":"Kho nhập","disabled":""}}):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Kho nhập")]),_c('v-select',{class:{
                  'is-invalid border-danger rounded': !!_vm.inValidObject[
                    'inventoryImport.WarehouseId'
                  ],
                },attrs:{"options":_vm.warehouses,"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chọn kho nhập"},on:{"input":function($event){_vm.inventoryImport.FromWarehouseId = null}},model:{value:(_vm.inventoryImport.WarehouseId),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "WarehouseId", $$v)},expression:"inventoryImport.WarehouseId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["inventoryImport.WarehouseId"])+" ")])],1)],1),_c('CCol',{attrs:{"md":"3"}},[(_vm.inventoryImport.Id)?_c('CInput',{attrs:{"value":_vm.inventoryImport.FromWarehouse
                  ? _vm.inventoryImport.FromWarehouse.Name
                  : '',"label":"Nhập từ kho","placeholder":"Kho xuất","disabled":""}}):_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Nhập từ kho")]),_c('v-select',{class:{
                  'is-invalid border-danger rounded': !!_vm.inValidObject[
                    'inventoryImport.FromWarehouseId'
                  ],
                },attrs:{"options":_vm.warehousesAll.filter(
                    function (_) { return _.Id != _vm.inventoryImport.WarehouseId; }
                  ),"reduce":function (p) { return p.Id; },"label":"Name","placeholder":"Chọn kho xuất"},model:{value:(_vm.inventoryImport.FromWarehouseId),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "FromWarehouseId", $$v)},expression:"inventoryImport.FromWarehouseId"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["inventoryImport.FromWarehouseId"])+" ")])],1)],1),_c('CCol',{attrs:{"md":"2"}},[(!_vm.allowEdit)?_c('CInput',{attrs:{"value":_vm.$moment(_vm.inventoryImport.ImportDate).format('DD/MM/YYYY HH:mm'),"label":"Thời gian nhập kho","placeholder":"Thời gian nhập kho","disabled":""}}):_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Thời gian nhập kho ")]),_c('datetime',{class:{
                  'is-invalid': !!_vm.inValidObject['inventoryImport.ImportDate'],
                },attrs:{"type":"datetime","format":"dd/MM/yyyy HH:mm","input-class":{
                  'form-control': true,
                  'is-invalid': !!_vm.inValidObject['inventoryImport.ImportDate'],
                },"value-zone":"local"},model:{value:(_vm.inventoryImport.ImportDate),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "ImportDate", $$v)},expression:"inventoryImport.ImportDate"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["inventoryImport.ImportDate"])+" ")])],1)],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Đơn vị giao hàng","placeholder":"Đơn vị giao hàng","disabled":!_vm.allowEdit ? true : false},model:{value:(_vm.inventoryImport.ShippingCpny),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "ShippingCpny", $$v)},expression:"inventoryImport.ShippingCpny"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Người giao hàng","placeholder":"Người giao hàng","disabled":!_vm.allowEdit ? true : false,"add-input-classes":{
                'is-invalid': !!_vm.inValidObject['inventoryImport.Shipper'],
              },"invalid-feedback":_vm.inValidObject['inventoryImport.Shipper']},model:{value:(_vm.inventoryImport.Shipper),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "Shipper", $$v)},expression:"inventoryImport.Shipper"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Thủ kho","placeholder":"Thủ kho","disabled":!_vm.allowEdit ? true : false,"add-input-classes":{
                'is-invalid': !!_vm.inValidObject[
                  'inventoryImport.WarehouseKeeper'
                ],
              },"invalid-feedback":_vm.inValidObject['inventoryImport.WarehouseKeeper']},model:{value:(_vm.inventoryImport.WarehouseKeeper),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "WarehouseKeeper", $$v)},expression:"inventoryImport.WarehouseKeeper"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Cước giao hàng ")]),_c('money',{staticClass:"form-control text-right",class:{
                  'is-invalid': !!_vm.inValidObject[
                    'inventoryImport.ShippingFee'
                  ],
                },attrs:{"disabled":!_vm.allowEdit,"placeholder":"Cước giao hàng","value":_vm.inventoryImport.ShippingFee},on:{"input":function (e) {
                    _vm.inventoryImport.ShippingFee = e
                      ? parseFloat(e) >= 0
                        ? parseFloat(e)
                        : 0
                      : 0;
                  }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["inventoryImport.ShippingFee"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('div',{staticClass:"form-group",attrs:{"role":"group"}},[_c('label',[_vm._v(" Cước chành xe ")]),_c('money',{staticClass:"form-control text-right",class:{
                  'is-invalid': !!_vm.inValidObject[
                    'inventoryImport.StrappingFee'
                  ],
                },attrs:{"disabled":!_vm.allowEdit,"placeholder":"Cước chành xe","value":_vm.inventoryImport.StrappingFee},on:{"input":function (e) {
                    _vm.inventoryImport.StrappingFee = e
                      ? parseFloat(e) >= 0
                        ? parseFloat(e)
                        : 0
                      : 0;
                  }}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.inValidObject["inventoryImport.StrappingFee"])+" ")])],1)]),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Tài xế","placeholder":"Tài xế","disabled":!_vm.allowEdit ? true : false,"add-input-classes":{
                'is-invalid': !!_vm.inValidObject['inventoryImport.Driver'],
              },"invalid-feedback":_vm.inValidObject['inventoryImport.Driver']},model:{value:(_vm.inventoryImport.Driver),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "Driver", $$v)},expression:"inventoryImport.Driver"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Bảo vệ","placeholder":"Bảo vệ","disabled":!_vm.allowEdit ? true : false,"add-input-classes":{
                'is-invalid': !!_vm.inValidObject['inventoryImport.Security'],
              },"invalid-feedback":_vm.inValidObject['inventoryImport.Security']},model:{value:(_vm.inventoryImport.Security),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "Security", $$v)},expression:"inventoryImport.Security"}})],1),_c('CCol',{attrs:{"md":"2"}},[_c('CInput',{attrs:{"label":"Ghi chú","placeholder":"Ghi chú","disabled":!_vm.allowEdit ? true : false,"add-input-classes":{
                'is-invalid': !!_vm.inValidObject['inventoryImport.Note'],
              },"invalid-feedback":_vm.inValidObject['inventoryImport.Note']},model:{value:(_vm.inventoryImport.Note),callback:function ($$v) {_vm.$set(_vm.inventoryImport, "Note", $$v)},expression:"inventoryImport.Note"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('div',{staticClass:"table-responsive"},[_c('table',{ref:"lstOrderDetails",staticClass:"table"},[_c('thead',[_c('tr',{staticClass:"bg-light text-dark"},[_c('th',[_vm._v("STT")]),_c('th',{staticStyle:{"min-width":"200px","max-width":"250px"}},[_vm._v(" Tên SP ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px","max-width":"150px"}},[_vm._v(" SL đặt hàng ")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"100px","max-width":"150px"}},[_vm._v(" SL thực nhận ")]),_c('th',{staticStyle:{"min-width":"100px","max-width":"200px"}},[_vm._v(" Ghi chú ")]),_c('th',{staticStyle:{"min-width":"50px","max-width":"50px"}})])]),_c('tbody',[(_vm.importItems && _vm.importItems.length)?_vm._l((_vm.importItems),function(importItem,i){return _c('tr',{key:("importItem-" + i)},[_c('td',[_vm._v(_vm._s(i + 1)+".")]),_c('td',{staticStyle:{"min-width":"200px","max-width":"250px"}},[(_vm.inventoryImport.Id)?[_vm._v(" "+_vm._s(importItem.ProductName)+" ")]:_c('a',{staticClass:"text-dark text-decoration-none",attrs:{"href":"javascript:"},on:{"click":function (e) {
                              _vm.updating = true;
                              _vm.editingDetail = Object.assign({}, importItem);
                            }}},[_vm._v(" "+_vm._s(importItem.ProductName)+" "),_c('CIcon',{attrs:{"name":"cil-pencil"}})],1)],2),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"100px","max-width":"150px"}},[_vm._v(" "+_vm._s(importItem.QtyOrder)+" ")]),_c('td',{staticClass:"text-right",staticStyle:{"min-width":"100px","max-width":"150px"}},[_vm._v(" "+_vm._s(importItem.QtyActual)+" ")]),_c('td',{staticStyle:{"min-width":"100px","max-width":"200px"}},[_vm._v(" "+_vm._s(importItem.Note)+" ")]),_c('td',{staticStyle:{"min-width":"50px","max-width":"50px"}},[(!_vm.inventoryImport.Id)?_c('a',{staticClass:"text-decoration-none text-danger",attrs:{"href":"javascript:"},on:{"click":function (e) {
                              _vm.importItems = _vm.importItems.filter(
                                function (_) { return _.id != importItem.id; }
                              );
                            }}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1):_vm._e()])])}):_vm._e(),(!_vm.inventoryImport.Id)?_c('tr',[_c('td'),_c('td',{staticClass:"text-right",attrs:{"colspan":"100%"}},[_c('a',{staticClass:"text-info text-decoration-none",attrs:{"href":"javascript:"},on:{"click":function (e) {
                            _vm.updating = true;
                            _vm.editingDetail = {
                              ProductId: 0,
                              QtyOrder: 0,
                              QtyActual: 0,
                              Note: null,
                            };
                          }}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Thêm Sản Phẩm ")],1)])]):_vm._e()],2)])])])],1),(_vm.inValidObject['importItems'])?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.inValidObject["importItems"])+" ")]):_vm._e(),_c('CRow',[_c('CCol',{attrs:{"md":"3"}},[(
                _vm.inventoryImport &&
                  _vm.inventoryImport.Id &&
                  _vm.importStatusOptions &&
                  _vm.importStatusOptions.length
              )?_c('CSelect',{attrs:{"label":"Trạng thái Nhập Kho:","placeholder":"Trạng thái","value":_vm.inventoryImportStatus,"options":[
                { value: null, label: 'Chọn Trạng Thái Nhập Kho' } ].concat( _vm.importStatusOptions ),"add-input-classes":{
                'is-invalid': !!_vm.inValidObject['inventoryImportStatus'],
              },"invalid-feedback":_vm.inValidObject['inventoryImportStatus']},on:{"update:value":function($event){_vm.inventoryImportStatus=$event}}}):_vm._e()],1)],1)],1)]},proxy:true}:null,{key:"footer",fn:function(){return [_c('div',{staticClass:"form-row w-100"},[(
            _vm.inventoryImport &&
              _vm.inventoryImport.Status != _vm.$const.IMPORT_STATUS.Cancelled &&
              _vm.inventoryImport.Status != _vm.$const.IMPORT_STATUS.Rejected
          )?_c('CCol',{staticClass:"text-left col-4"},[_c('receipt',{attrs:{"inventoryImport":_vm.inventoryImport}})],1):_vm._e(),_c('CCol',{staticClass:"text-right col-8 ml-auto"},[(_vm.allowEdit)?[_c('CButton',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.saving},on:{"click":_vm.createInventoryImport}},[_c('CIcon',{attrs:{"name":"cil-save","custom-classes":"c-icon m-0"}}),_vm._v(" Cập nhật ")],1),(_vm.saving)?_c('div',{staticClass:"d-inline-block font-italic"},[_c('CSpinner',{staticClass:"ml-2",attrs:{"color":"info","size":"sm"}}),_vm._v(" Đang xử lý... ")],1):_vm._e()]:_vm._e(),_c('CButton',{staticClass:"btn btn-secondary ml-2",on:{"click":function($event){_vm.detailModal = false}}},[_c('span',[_vm._v("×")]),_vm._v(" Đóng ")])],2)],1)]},proxy:true}],null,true)},[(_vm.loading)?_c('div',{staticClass:"spinner-border m-5",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_vm._e()]),_c('choose-product',{attrs:{"updating":_vm.updating,"editingDetail":_vm.editingDetail},on:{"update:updating":function($event){_vm.updating=$event},"choose":_vm.updateImportDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }